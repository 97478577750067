import ZeusService from '@/common/services/Zeus';
import DataAPIService from '@/common/services/DataAPI';
import splitSort from '@/common/helpers/split-sort';
import { prefStorage } from '@samknows/utils';

export const namespaced = true;

export const state = {
  token: undefined,
  formData: [],
  metadata: {}
};

/* eslint-disable no-shadow */
export const mutations = {
  setToken(state, token) {
    state.token = token;
  },
  setFormData(state, formDataArray) {
    if (!Array.isArray(formDataArray)) {
      formDataArray = [formDataArray];
    }

    state.formData = formDataArray.map((formData) =>
      Object.assign({}, formData)
    );
  },
  setMetadata(state, metadata) {
    state.metadata = metadata;
  }
};

export const actions = {
  getToken({ commit }, panelID) {
    commit('setToken', '');
    return ZeusService.get(`panel/${panelID}/data_api`).then((res) =>
      commit('setToken', res.data)
    );
  },
  updateToken({ commit }, panelID) {
    commit('setToken', '');
    return ZeusService.update(`panel/${panelID}/data_api`).then((res) =>
      commit('setToken', res.data)
    );
  },
  updateMetadata({ commit, state }, panelId) {
    const token = state.token;
    const dataApiGrants = prefStorage.get('grants').Apollo.data_api;

    function extractMetricFactory(array) {
      return (metricId) => {
        const index = array.findIndex(({ id }) => id === metricId);
        return array.splice(index, 1)[0];
      };
    }

    const metrics = DataAPIService.get('metrics', token).then(
      (res) => res.data
    );

    const tests = DataAPIService.get('tests', token).then((res) => res.data);

    const splits = DataAPIService.get('splits', token).then((res) => {
      const splittables = res.data.sort((a, b) => (a.id < b.id ? -1 : 1));

      const firstSplittables = ['isp', 'package', 'unit', 'mac'].map(
        extractMetricFactory(splittables)
      );
      splittables.unshift(...firstSplittables, { id: 'dropdown-separator' });

      return splittables;
    });

    const filters = DataAPIService.get('filters', token).then((res) => {
      const filterables = res.data;
      filterables
        .sort((a, b) => {
          if (a.customDefined !== b.customDefined) {
            return a.customDefined ? 1 : -1;
          }

          return a.id < b.id ? -1 : 1;
        })
        .forEach((filterable) => {
          if (filterable.values) {
            if (filterable.id === 'package') {
              filterable.values = splitSort(filterable.values, 'identifier');
            } else if (!['day', 'date', 'hour'].includes(filterable.id)) {
              filterable.values.sort((a, b) =>
                a.identifier < b.identifier ? -1 : 1
              );
            }

            if (filterable.id === 'isp') {
              const ispGrants = dataApiGrants
                .filter(({ panel, isp }) => panel === panelId && isp)
                .map(({ isp }) => isp);

              if (ispGrants.length) {
                // @todo: remove Number() when API fixed
                filterable.values = filterable.values.filter(({ id }) =>
                  ispGrants.includes(Number(id))
                );
              }
            }

            if (filterable.id === 'country') {
              const countryGrants = dataApiGrants
                .filter(({ panel, country }) => panel === panelId && country)
                .map(({ country }) => country);

              if (countryGrants.length) {
                // @todo: remove Number() when API fixed
                filterable.values = filterable.values.filter(({ id }) =>
                  countryGrants.includes(Number(id))
                );
              }
            }
          }
        });

      const first = ['isp', 'package', 'unit', 'mac'].map(
        extractMetricFactory(filterables)
      );
      const second = ['date', 'day', 'hour'].map(
        extractMetricFactory(filterables)
      );

      filterables.unshift(
        ...first.filter((f) => f),
        { id: 'dropdown-separator' },
        ...second.filter((f) => f),
        { id: 'dropdown-separator' }
      );

      return filterables;
    });

    return Promise.all([metrics, tests, splits, filters]).then((metadata) => {
      commit('setMetadata', {
        ...state.metadata,
        ...{
          metrics: metadata[0],
          tests: metadata[1],
          splittables: metadata[2],
          filterables: metadata[3]
        }
      });
    });
  },
  setFormData(state, formDataArray) {
    if (!Array.isArray(formDataArray)) {
      formDataArray = [formDataArray];
    }

    state.formData = formDataArray.map((formData) =>
      Object.assign({}, formData)
    );
  }
};
