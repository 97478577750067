import {
  ActionTree,
  GetterTree,
  MutationTree,
  Module as VuexModule
} from 'vuex';
import config from '@/common/config';
import { PromiseResponse } from '@/common/global.interfaces';
import type { RootState } from '@/common/store';
import { Http } from '@samknows/utils';

export enum ApiType {
  DATA_API = 'data_api',
  METADATA_API = 'metadata_api',
  INSTANT_TEST_API = 'instant_test_api',
  TEST_SCHEDULE_AND_MOVE_API = 'shared_api'
}

export interface Token {
  id: number;
  name: string;
  apiName: ApiType;
  isLegacyToken: boolean;
  createdAt: string | null;
  updatedAt: string | null;
}

export interface ApiTokenList {
  apiType: ApiType;
  tokens: Token[];
}

type ApiTokens = {
  [api in ApiType]?: Token[];
};

export interface ApisViewState {
  apis: ApiDetails[];
  apiTokens: ApiTokenList[];
}

export interface ApiDetails {
  name: ApiType;
  i18nKey: string;
  link: string;
  docLink: string;
}

const apiInfo = [
  {
    name: ApiType.DATA_API,
    i18nKey: 'DataApi',
    link: 'https://data-api.samknows.one',
    docLink: 'https://samknows.com/technology/apis/data-api'
  },
  {
    name: ApiType.METADATA_API,
    i18nKey: 'MetadataApi',
    link: 'https://metadata-api.samknows.one',
    docLink: 'https://samknows.com/technology/apis/metadata-api'
  },
  {
    name: ApiType.INSTANT_TEST_API,
    i18nKey: 'InstantTestApi',
    link: 'https://instant-test-api.samknows.one',
    docLink: 'https://samknows.com/technology/apis/instant-test-api'
  },
  {
    name: ApiType.TEST_SCHEDULE_AND_MOVE_API,
    i18nKey: 'TestScheduleAndMoveApi',
    link: 'https://agent-activation-api.samknows.one',
    docLink: 'https://samknows.com/technology/apis/agent-activation-api'
  }
];

const state = (): ApisViewState => ({
  apis: apiInfo,
  apiTokens: []
});

const getters: GetterTree<ApisViewState, RootState> = {
  apis: (state): ApiDetails[] => {
    return state.apis;
  },
  apiTokens: (state): ApiTokenList[] => {
    return state.apiTokens;
  }
};

const mutations: MutationTree<ApisViewState> = {
  setApiTokens: (state, tokens) => {
    state.apiTokens = tokens;
  }
};

const actions: ActionTree<ApisViewState, RootState> = {
  async loadAllTokens({ commit, rootState }): Promise<void> {
    const panelId = rootState.panel.pid;

    try {
      const loadApiTokens: PromiseResponse<ApiTokens[]> = await Http.request(
        `${config.api.zeus}/panels/${panelId}/api_tokens`
      );

      const apiKeys = Object.keys(loadApiTokens?.data) as ApiType[];

      const apiTokensList: ApiTokenList[] = [];
      apiKeys.forEach((api) => {
        apiTokensList.push({
          apiType: api,
          tokens: loadApiTokens?.data[api]
        });
      });

      commit('setApiTokens', apiTokensList);
    } catch (error) {
      throw new Error();
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as VuexModule<ApisViewState, RootState>;
