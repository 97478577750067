import SkConfig from '@/common/config';
import { RequestOptions } from '@samknows/utils';

export interface GenerateFileData {
  url: string;
}

export default {
  get(endpoint: string, authorization: string) {
    return fetch(`${SkConfig.api.data_api}/${endpoint}`, {
      headers: {
        authorization,
        'Content-Type': 'application/json'
      }
    }).then((res) => res.json());
  },
  postTestData(body: unknown, authorization: string) {
    return fetch(`${SkConfig.api.data_api}/test_data`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        authorization,
        'Content-Type': 'application/json'
      }
    });
  },
  generateFileURL(
    body: unknown,
    authorization: string,
    endpoint: string,
    options: RequestOptions = {}
  ): Promise<GenerateFileData> {
    return fetch(`${SkConfig.api.data_api}/${endpoint}_download`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        authorization,
        Accept: 'text/csv',
        'Content-Type': 'application/json'
      },
      ...options
    }).then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.json();
    });
  }
};
