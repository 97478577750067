import config from '@/common/config';
import {
  METRIC_KEYS,
  MeasurementBuilderFormData,
  Metric,
  PromiseResponse
} from '@/common/global.interfaces';
import { dataToSeries } from '@/common/helpers/data-visualisations-utils';
import * as dateUtils from '@/common/helpers/date-utils';
import { logError } from '@/common/helpers/error-handling';
import * as measurementUtils from '@/common/helpers/measurements-utilities';
import {
  AvailableUnitMetrics,
  ChartData,
  ChartInputData,
  DISCONNECTION_ERRORS,
  DisconnectionMetricData,
  DisconnectionsResults,
  MetricResultsData,
  OldSkippedTestsResults,
  ScheduledTestsResultsForDay,
  UnitAnalyticsSkippedTestsResults
} from '@/features/unit-tests-view/types';
import { EventBus, Http, dateParse } from '@samknows/utils';
import { getUnitAnalyticsChartData } from '../helpers/UnitTests.helpers';
import { UNIT_TEST_SCHEDULES_TASKS } from '@samknows/instant-test';

enum PROTOCOLS {
  TCP = 'TCP',
  UDP = 'UDP'
}

export const getNewYValue = (item: DisconnectionMetricData): number | null => {
  // If item passed isn't required type then log error and fail gracefully
  if (!item || typeof item !== 'object') {
    logError(new Error(`argument passed is not of expected type: ${item}`));
    return null;
  }

  // If no metricValue then it is currently an ongoing disconnection so we calculate the duration from start to now
  const itemValue = item.metricValue;
  const startTime = item.dtime;

  // If we don't have required data then log error and fail gracefully
  if (!itemValue && !startTime) {
    logError(
      new Error(
        `No metricValue or dtime which are required: ${JSON.stringify(item)}`
      )
    );
    return null;
  }

  // If not a number then log error and fail gracefully
  if (itemValue && typeof itemValue !== 'number') {
    logError(new Error(`MetricValue not a number: ${JSON.stringify(item)}`));
    return null;
  }

  const durationTillNow =
    dateUtils.durationToDate(startTime, Date.now()) * 1000;
  return !itemValue ? durationTillNow : itemValue;
};

export const getNewXValue = (item: DisconnectionMetricData): Date => {
  // for disconnections response so use updated key
  let endTime = item?.endDtime;
  const isOngoingDisconnection = !endTime;

  // If endDtime is empty then it is an ongoing disconnection and we need to display that
  // TODO - there could potentially be odd visuals due to timezone issues by using browsers now time.
  endTime = isOngoingDisconnection ? dateUtils.formatDate(Date.now()) : endTime;
  return dateParse(endTime);
};

export const transformNewDisconnectionsData = (
  highchartsData: ChartData[]
): ChartData[] => {
  if (!Array.isArray(highchartsData)) {
    logError(new Error(`Expected array: ${JSON.stringify(highchartsData)}`));
    return [];
  }

  return highchartsData.map((topLevelData) => {
    const { data } = topLevelData;

    if (!Array.isArray(data)) {
      logError(new Error(`Expected array: ${JSON.stringify(data)}`));
      return topLevelData;
    }

    const updatedData = data.map((innerData) => {
      const { data: apiData } = innerData;
      const endDtime = apiData?.endDtime || null;
      const isOngoingDisconnection = !endDtime;
      const newYVal = getNewYValue(apiData as DisconnectionMetricData);
      const newXVal = getNewXValue(apiData as DisconnectionMetricData);
      innerData.y = newYVal;
      innerData.x = newXVal;
      innerData.isOngoingDisconnection = isOngoingDisconnection;
      return innerData;
    });

    topLevelData.data = updatedData;
    return topLevelData;
  });
};

export async function getUnitDisconnections(
  formData: MeasurementBuilderFormData[],
  unitId: number,
  params?: Record<string, string>
): Promise<PromiseResponse<DisconnectionsResults | Error>> {
  const requestArray = measurementUtils.getRequestDataArray(formData);
  const requestObj = requestArray[0];

  requestObj.requestData = {
    ...requestObj.requestData,
    date: {
      from: dateUtils.relativeDateToDate(requestObj.requestData.date.from),
      to: dateUtils.relativeDateToDate(requestObj.requestData.date.to)
    }
  };

  try {
    // TODO: Fix the type in the next line if you can
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const apiResponse = await Http.get<any>(
      config.api.unitAnalytics,
      `/${unitId}/disconnections`,
      params
    );
    // rename 'results' property to 'metricData'
    apiResponse.data['metricData'] = apiResponse.data['results'];
    delete apiResponse.data['results'];
    const dataError: DISCONNECTION_ERRORS = apiResponse.data?.error;

    // convert data object to array
    const { dataSources, metricMetadata, dataUsage, metricData, error } =
      apiResponse.data;
    const apiData = [
      { dataSources, metricMetadata, dataUsage, metricData, error }
    ];
    apiResponse.data = apiData;
    const responseData = apiResponse;
    const data = { ...requestObj, responseData };

    if (DISCONNECTION_ERRORS[dataError]) {
      return {
        data: data.responseData.data
      };
    }

    // Resolve diffs between what the front-end expects and the backend sends
    const transformedData = measurementUtils.transformData([data]);

    // Merge responses together
    const mergedResponses = measurementUtils.consolidateResponses(
      transformedData,
      formData
    );

    // Convert data into Highcharts-readable data
    let highchartsData = dataToSeries(mergedResponses, formData);
    highchartsData = transformNewDisconnectionsData(highchartsData);

    return {
      data: highchartsData
    };
  } catch (error) {
    return Promise.reject(error);
  }
}

export function getSkippedTests(
  unitId: string,
  params?: Record<string, string>
): Promise<PromiseResponse<OldSkippedTestsResults>> {
  return Http.get(config.api.analytics, `units/${unitId}/breaches`, params);
}

export function getUnitAnalyticsSkippedTests(
  unitId: string,
  params?: Record<string, string>
): Promise<PromiseResponse<UnitAnalyticsSkippedTestsResults>> {
  return Http.get(config.api.unitAnalytics, `/${unitId}/skipped_tests`, params);
}

interface GetScheduledTestsArgs {
  unitId: number;
  date: string;
  metric: METRIC_KEYS;
}

export function getScheduledTestsForDay(data: GetScheduledTestsArgs): Promise<
  PromiseResponse<{
    results: ScheduledTestsResultsForDay[];
  }>
> {
  const { unitId, date, metric } = data || {};
  const params = {
    date,
    metric,
    localTargetSetResultsOnly: '1'
  };

  return Http.get(
    config.api.unitAnalytics,
    `${unitId}/scheduled_tests_daily`,
    params
  );
}

// NEW API
export const getMetricData = (data: {
  url: string;
  requestBody: Record<string, unknown>;
  metric: Partial<Metric> | METRIC_KEYS;
  signal?: AbortSignal;
}): Promise<PromiseResponse<MetricResultsData>> => {
  const { url, requestBody, metric, signal } = data;
  requestBody.metric = metric;

  return Http.request(url, {
    method: 'POST',
    body: JSON.stringify(requestBody),
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    signal: signal
  });
};

const getDailyScheduledTestsRequests: Record<string, AbortController> = {};

const abortAllDailyScheduledTestsRequests = (): void => {
  const keys = Object.keys(getDailyScheduledTestsRequests);
  keys.forEach((key) => {
    const controller = getDailyScheduledTestsRequests[key];
    if (controller) {
      controller.abort();
      delete getDailyScheduledTestsRequests[key];
    }
  });
};

export function getSpeedMetricsByProtocol(
  availableTests: UNIT_TEST_SCHEDULES_TASKS[]
): {
  download: METRIC_KEYS;
  upload: METRIC_KEYS;
} {
  const metrics = {
    [PROTOCOLS.TCP]: {
      download: METRIC_KEYS.DOWNLOAD,
      upload: METRIC_KEYS.UPLOAD
    },
    [PROTOCOLS.UDP]: {
      download: METRIC_KEYS.UDP_DOWNLOAD,
      upload: METRIC_KEYS.UDP_UPLOAD
    }
  };

  const hasAvailableTests = !!availableTests.length;
  const hasTcpTests =
    availableTests.includes(UNIT_TEST_SCHEDULES_TASKS.HTTP_DOWNLOAD_MT) ||
    availableTests.includes(UNIT_TEST_SCHEDULES_TASKS.HTTP_UPLOAD_MT);
  const hasUdpTests =
    availableTests.includes(UNIT_TEST_SCHEDULES_TASKS.UDP_DOWNLOAD) ||
    availableTests.includes(UNIT_TEST_SCHEDULES_TASKS.UDP_UPLOAD);

  if (hasTcpTests || !hasAvailableTests) {
    return metrics[PROTOCOLS.TCP];
  } else if (hasUdpTests) {
    return metrics[PROTOCOLS.UDP];
  } else {
    throw new Error(
      'Attempted to find TCP or UDP tests but neither was available in the nonempty list given'
    );
  }
}

export const getDailyScheduledTests = (
  unitId: number,
  hasRestrictedMetrics: boolean,
  data: Record<string, string>,
  timePeriod: string,
  aggregation: string,
  availableTests: UNIT_TEST_SCHEDULES_TASKS[],
  abortAll?: boolean
): Promise<PromiseResponse<MetricResultsData>[]> => {
  if (abortAll) {
    abortAllDailyScheduledTestsRequests();
  }

  const abortController = new AbortController();
  const signal = abortController.signal;
  const requestId = `${unitId}_${timePeriod}_${
    data.to
  }_${new Date().toISOString()}`;
  getDailyScheduledTestsRequests[requestId] = abortController;

  const url = `${config.api.unitAnalytics}/${unitId}/metric_results`;

  const requestBody = {
    date: {
      from: data.from,
      to: data.to
    },
    includeAggregated: true,
    includeTotal: true,
    localTargetSetResultsOnly: true,
    aggregation
  };

  const restrictedPromises = hasRestrictedMetrics
    ? []
    : [
        getMetricData({
          url,
          requestBody,
          metric: getSpeedMetricsByProtocol(availableTests).upload,
          signal: signal
        }),
        getMetricData({
          url,
          requestBody,
          metric: getSpeedMetricsByProtocol(availableTests).download,
          signal: signal
        })
      ];

  const packetLossData = getMetricData({
    url,
    requestBody,
    metric: METRIC_KEYS.PACKET_LOSS,
    signal: signal
  });
  const latencyData = getMetricData({
    url,
    requestBody,
    metric: METRIC_KEYS.LATENCY
  });

  const nonRestrictedPromises = [
    ...restrictedPromises,
    packetLossData,
    latencyData
  ];

  return Promise.all(nonRestrictedPromises)
    .then((res: PromiseResponse<MetricResultsData>[]) => {
      res.forEach((item: PromiseResponse<MetricResultsData>) => {
        EventBus.$emit('data-usage', {
          endpoint: 'metric_results',
          dataUsed: item?.data?.dataUsage?.totalBytesProcessed,
          metric: item?.data?.metricMetadata?.metricKey || '',
          timePeriod
        });
      });
      delete getDailyScheduledTestsRequests[requestId];
      return res;
    })
    .finally(() => {
      delete getDailyScheduledTestsRequests[requestId];
    });
};

const fetchMetricResults = async (
  formData: MeasurementBuilderFormData,
  unitId: number,
  timePeriod: string,
  includeTotal = false
): Promise<{
  data: MetricResultsData;
  metricKey: Partial<Metric> | METRIC_KEYS;
  formData: MeasurementBuilderFormData;
}> => {
  const url = `${config.api.unitAnalytics}/${unitId}/metric_results`;
  const requestBody = {
    date: formData?.date,
    includeAggregated: true,
    aggregation: formData?.timeAggregation,
    includeTotal: includeTotal,
    localTargetSetResultsOnly: true
  };

  const res = await getMetricData({
    url,
    requestBody,
    metric: formData?.metric
  });

  EventBus.$emit('data-usage', {
    endpoint: 'metric_results',
    dataUsed: res?.data?.dataUsage?.totalBytesProcessed,
    metric: formData?.metric || '',
    timePeriod
  });

  return {
    data: res?.data,
    metricKey: formData?.metric,
    formData
  };
};

export const getMetricResultsChartData = async (
  formData: MeasurementBuilderFormData,
  unitId: number,
  timePeriod: string
): Promise<ChartInputData> => {
  const includeTotal = true;
  const metricData = await fetchMetricResults(
    formData,
    unitId,
    timePeriod,
    includeTotal
  );
  return getUnitAnalyticsChartData(
    metricData?.data,
    metricData?.metricKey as string,
    [metricData?.formData]
  );
};

export const getAvailableUnitMetrics = (
  unitId: number
): Promise<PromiseResponse<AvailableUnitMetrics>> => {
  return Http.get<PromiseResponse<AvailableUnitMetrics>>(
    config.api.unitAnalytics,
    `${unitId}/metrics`
  ).then((data) => {
    data.data.metrics.forEach((metric) => {
      metric.type = measurementUtils.realTypeToUsefulType(metric.type);
    });

    return data;
  });
};
