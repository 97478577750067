import ZeusService from '@/common/services/Zeus';
import { UNIT_TEST_SCHEDULES_TASKS } from '@samknows/instant-test';
import { Unit } from '@/common/global.interfaces';
import { UnreachableCaseError } from '@/common/helpers/UnreachableCaseError';

export enum CALLER_TYPE {
  INSTANT_TESTS = 'instant_tests',
  SCHEDULED_TESTS = 'scheduled_tests'
}

export async function fetchAvailableTests(
  unit: Unit,
  callerType: CALLER_TYPE
): Promise<UNIT_TEST_SCHEDULES_TASKS[]> {
  const BACKEND_PAGINATION = 50;

  // use panel default test schedule if none is set
  const lmapConfig =
    unit.lmap_config?.data?.id ?? unit.panel.data.default_lmap_config;

  const query: Record<string, unknown> = {
    include: 'task'
  };

  switch (callerType) {
    case CALLER_TYPE.INSTANT_TESTS:
      query.isTriggerable = true;
      break;
    case CALLER_TYPE.SCHEDULED_TESTS:
      query.perPage = BACKEND_PAGINATION;
      break;
    default:
      throw new UnreachableCaseError(callerType);
  }

  const { data } = await ZeusService.get(
    `panels/${unit.panel.data.id}/lmap/configs/${lmapConfig}/tests`,
    query
  );

  return data.map((test) => test.task.data.name);
}
